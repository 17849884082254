import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em 0",
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down("xs")]: {
      padding: "3em 1.25em",
    },
  },
  button: {
    border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(2, 5),
  },
  link: {
    fontSize: "1.1875rem",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  h4: {
    fontFamily: 'Open Sans, Work Sans',
    fontSize: "2.25rem",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
})

function IndexContactBtn(props) {
  const { classes } = props

  return (
    <Container className={classes.root} component="section">
      <Button
        className={classes.button}
        color="secondary"
        component={Link}
        to="/contact/"
      >
        <Typography
          variant="h4"
          component="span"
          color="secondary"
          align="center"
          className={classes.h4}
        >
          HAVE QUESTIONS? NEED HELP?
        </Typography>
      </Button>
      <Typography variant="subtitle1" className={classes.link}>
        We are here to help. Get in touch!
      </Typography>
    </Container>
  )
}

IndexContactBtn.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(IndexContactBtn)
